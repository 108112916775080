.swiss-army-select.form-control {
  // Bootstrap calculates the height of a form-control and this causes the react-select component to grow
  // outside of the form-control container.  We unset the height here so that the from-control will grow
  // vertically and not be stuck at the calculated height.
  height: unset;

  > div {
    // the control container
    border: none;

    max-width: inherit;
  }

  box-shadow: none;

  &:focus {
    border-color: unset;
  }

  &:hover {
    border-color: unset;
  }

  > div:nth-child(3) {
    // dropdown menu
    margin-top: 0em;
    background-color: white;
  }

  div {
    // general text
    color: black;

    > svg {
      // indicators
      font-size: 1em;
      color: #878787;
    }
  }
}
