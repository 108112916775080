.modal {
  &.show {
    z-index: 1001; /* needed to put modals behind Material-UI pickers */
  }

  .modal-content {
    box-shadow: $modal-content-box-shadow-xs;
    border-radius: 0;
    border: none;
  }
}

.modal-backdrop.show {
  z-index: 1000; /* needed to put modals behind Material-UI pickers */
}
